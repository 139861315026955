import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import { MOBILMEDIA } from './helper/constants';
import inView from './helper/inView';

outline();

let moiblenavLoaded = false;
const sliderMap = new Map();
const sliderDragging = new Map();

function loadMobilenav() {
  if (moiblenavLoaded === true) {
    return;
  }

  import('./components/offcanvas')
    .then((offcanvas) => {
      moiblenavLoaded = true;

      const showMenuBtn = $('.show-menu');
      const navigation = offcanvas.default($('.mobile-nav'));
      showMenuBtn.addEventListener('click', navigation.open, { passive: true });
    })
    .catch((err) => {
      console.warn('Chunk offcanvas.js loading failed', err);
    });
}

MOBILMEDIA.addListener((e) => {
  if (e.matches) {
    loadMobilenav();
  }
});

export default function () {
  if (MOBILMEDIA.matches) {
    loadMobilenav();
  }

  if ($('.dtpicker') !== null) {
    import('./components/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk form.js loading failed', err);
      });
  }

  const lightboxes = $$('.lightbox');
  inView(lightboxes, () => {
    import('./components/lightbox')
      .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }, true);

  const sliders = $$('.siema');
  inView(sliders, () => {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }, true);

  // const bsElements = $$('.nav-tabs, [data-toggle="collapse"]');
  // inView(bsElements, () => {
  //   const fadeTab = (e) => {
  //     const href = e.relatedTarget.getAttribute('href');
  //     TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
  //   };

  //   import('../libs/bs-4-native')
  //     .then((bsn) => {
  //       if ('bootstrapNative' in window === false) {
  //         window.bootstrapNative = bsn;
  //       }
  //       const tabs = $$('.nav-tabs .tab-link');
  //       for (let i = 0; i < tabs.length; i++) {
  //         const el = tabs[i];

  //         // fade in new tab content
  //         el.addEventListener('hide.bs.tab', fadeTab, false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.warn('Chunk bs4-native.js loading failed', err);
  //     });
  // }, true);
}
